<template>
  <a-config-provider :locale="locale">
    <a-spin ref="globalSpin" :spinning="isLoading" :tip="loadingTip" size="large">
      <div>
        <router-view/>
      </div>
    </a-spin>
  </a-config-provider>
</template>

<script>
import { computed, provide, reactive, ref, toRefs } from 'vue'
import { useStore } from 'vuex'
import enUS from 'ant-design-vue/es/locale/en_US'
import koKR from 'ant-design-vue/es/locale/ko_KR'

koKR.Pagination.items_per_page = '/ 페이지'

export default {
  setup () {
    const globalSpin = ref(null)
    const store = useStore()
    const locale = computed(() => {
      const langs = {
        'en-US': enUS,
        'ko-KR': koKR
      }
      return langs[store.state.app.locale]
    })

    const state = reactive({
      isLoading: false,
      loadingTip: ''
    })
    const showGlobalSpin = (text = '') => {
      state.loadingTip = text
      state.isLoading = true
    }
    const hideGlobalSpin = () => {
      state.isLoading = false
    }

    provide('globalSpin', {
      show: showGlobalSpin,
      hide: hideGlobalSpin
    })

    return {
      globalSpin,
      ...toRefs(state),
      locale
    }
  }
}
</script>
<style lang="less">
</style>
