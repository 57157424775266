<template>
  <a-modal
    v-model:visible="visible"
    :closable="false"
    :footer="false"
    :keyboard="false"
    :maskClosable="false"
    class="onboarding-modal">
    <button aria-label="Close" class="custom-modal-close" type="button" @click="closeModal">
      <span class="ant-modal-close-x">
        <span aria-label="close" class="anticon anticon-close ant-modal-close-icon" role="img">
          <svg aria-hidden="true" class="" data-icon="close" fill="currentColor" focusable="false" height="1em" viewBox="64 64 896 896" width="1em">
            <path
              d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
          </svg>
        </span>
      </span>
    </button>
    <div>
      <div class="page">
        <img src="@/assets/images/onboard_img01.jpg">
        <h4>
          유캔싸인은 PC 환경에 최적화되어 있어요.<br/>
          PC에서 접속하여 이용 가이드 확인 후,<br/>
          계약을 생성해 보세요!<br/>
          <a-button class="btn" type="primary" @click="closeModal">확인</a-button>
        </h4>
        <a-checkbox v-model:checked="dontShowAgain">
          다음부터 보지 않기
        </a-checkbox>
      </div>
    </div>
  </a-modal>
</template>

<script>
import { reactive, toRefs } from 'vue'
import { setNotice as submit } from 'api/user'
import { Modal } from 'ant-design-vue'

export default {
  emits: ['confirm'],
  setup (props, { emit }) {
    const state = reactive({
      dontShowAgain: false,
      showConfirmModal: false,
      visible: false,
      currentPage: 0
    })

    const show = () => {
      state.visible = true
    }

    const hide = () => {
      state.visible = false
      emit('confirm')
    }

    const closeModal = () => {
      if (state.dontShowAgain) {
        submit({ type: 'welcome_mobile' }).then(() => {
          hide()
        }).catch((err) => {
          Modal.error({ content: err.msg })
        })
      } else {
        hide()
      }
    }

    return {
      ...toRefs(state),
      show,
      hide,
      closeModal
    }
  }

}
</script>

<style lang="less" scoped>
.page {
  display: flex;
  justify-content: center;
  flex-direction: column;

  .btn {
    height: 42px;
    line-height: 42px;
    padding: 0;
    margin: 50px auto;
    width: 200px;
  }

  img {
    object-fit: none;
    margin-bottom: -10px
  }

  h4 {
    text-align: center;
    font-size: 16px;
    line-height: 28px;
    font-weight: 400;
    color: #555;
  }
}

.custom-modal-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  padding: 0;
  color: rgba(0, 0, 0, 0.45);
  font-weight: 700;
  line-height: 1;
  text-decoration: none;
  background: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
  transition: color 0.3s;
}
</style>
