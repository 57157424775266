<template>
  <a-modal
    v-model:visible="visible"
    :closable="false"
    :footer="false"
    :keyboard="false"
    :maskClosable="false"
    class="onboarding"
    height="720px"
    width="980px"
  >
    <h3>유캔싸인에 오신 것을 환영합니다 <img src="@/assets/images/fire.svg"></h3>
    <div class="onboarding-box">
      <div class="accordion">
        <ul>
          <li @click="step = 1">
            <i>1</i>
            <div>
              <h4>서명요청 시작하기</h4>
              <p v-show="step === 1">
                [서명 요청하기] 버튼 클릭하여<br/>
                사용하던 문서 양식 그대로 업로드하고<br/>
                유캔싸인 전자계약 문서로 만드세요.
              </p>
            </div>
          </li>
          <li @click="step = 2">
            <i>2</i>
            <div>
              <h4>참여자/서명 설정하기</h4>
              <p v-show="step === 2">
                참여자 이름, 연락처 정보 입력 후<br/>
                참여자가 직접 입력할 필드를 추가하세요.

              </p>
            </div>
          </li>
          <li @click="step = 3">
            <i>3</i>
            <div>
              <h4>결제요청 설정하기</h4>
              <p v-show="step === 3">
                전자계약과 함께 결제가 필요한 경우<br/>
                Signpay를 설정하여 결제까지 한 번에<br/>
                받아보세요
              </p>
            </div>
          </li>
        </ul>
      </div>
      <!--
      <img v-show="step === 1" src="@/assets/images/onboarding01.jpg">
      <img v-show="step === 2" src="@/assets/images/onboarding02.jpg">
      <img v-show="step === 3" src="@/assets/images/onboarding03.jpg">
      -->
      <div class="slide-wrap">
        <div class="slider step-slider">
          <div v-if="step === 1">
            <div class="slide slide1">
              <div class="box">
                <h3>서명할 문서 선택</h3>
                <div class="cont">
                  <div class="left">
                    <ul>
                      <li></li>
                      <li></li>
                      <li></li>
                    </ul>
                  </div>
                  <div class="right">
                    <div>
                      <h4>
                        <span class="txt txt1">업로드할 파일을 이곳에 끌어다 놓으세요.</span>
                        <span class="txt txt2">문서 선택이 완료되었습니다.</span>
                      </h4>
                      <div class="input-txt">
                        <span class="txt txt1"></span>
                        <span class="txt txt2">그랜드주식회사_표준계약서.pdf</span>
                      </div>
                      <div class="click">시작하기</div>
                      <div class="file-img">
                        <img src="@/assets/images/onboarding/file_img1.png">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else-if="step === 2">
            <div class="slide slide2">
              <div class="box">
                <div class="top">
                  <i class="blue">1</i> 첫번째 서명 참여자
                </div>
                <div class="input-txt">이름 또는 회사명</div>
                <div class="tel"></div>
                <div class="choice">
                  <div class="input-txt txt txt1">이메일 주소</div>
                  <div class="txt txt2">
                    <select>
                      <option>010</option>
                    </select>
                    <div class="input-txt">'-'없이 숫자만 입력</div>
                  </div>
                </div>
                <div class="flex-sb">
                  <span>이메일 전송</span>
                  <span>카카오톡 전송</span>
                </div>
              </div>
              <div class="box add">
                <i>+</i> 서명 참여자 추가
              </div>
            </div>
          </div>
          <div v-else-if="step === 999">
            <div class="slide slide3">
              <div class="box">
                <div class="top">
                  그랜드주식회사 계약서
                </div>
                <div class="gray"></div>
                <div class="sign-box">
                  <div class="label">사인/도장</div>
                  <div class="square"></div>
                </div>
                <div class="label1">계약금 5,000,000원</div>
                <div class="label2">대표자명</div>
                <div class="input-txt">텍스트</div>
                <div class="tool">
                  <div class="sign">사인/도장</div>
                  <div class="sign-mov">사인/도장</div>
                  <div class="textbox">텍스트</div>
                  <div class="textbox-mov">텍스트</div>
                  <div class="checkbox">체크박스</div>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <div class="slide slide4">
              <div class="box">
                <div class="top">
                  <img src="@/assets/images/onboarding/signpay_logo.svg">
                  <span class="switch">
                  <i class="circle"></i>
                </span>
                </div>
                <div class="txt-box">
                  <h5>상품명</h5>
                  <div class="input-txt">계약금</div>
                  <h5>결제요청 금액(단위:원)</h5>
                  <div class="input-txt">5,000,000</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="onboarding-btm">
        <span>
          <a-checkbox v-model:checked="dontShowAgain">
            다음부터 보지 않기
          </a-checkbox>
        </span>
      <button v-if="step !== 3" @click="step++">다음</button>
      <button v-else @click="closeModal">시작하기</button>
    </div>
  </a-modal>
</template>

<script>
import { useGtag } from 'vue-gtag-next'
import { setNotice as submit } from 'api/user'
import { Modal } from 'ant-design-vue'
import { reactive, toRefs } from 'vue'

export default {
  components: {},
  emits: ['confirm'],
  setup (props, { emit }) {
    const { event } = useGtag()
    const state = reactive({
      dontShowAgain: false,
      visible: false,
      step: 1
    })

    const show = () => {
      event('온보딩모달_Open')
      state.visible = true
    }

    const hide = () => {
      emit('confirm')
      state.visible = false
    }

    const onChange = current => {
      state.step = current
    }

    const closeModal = () => {
      if (state.dontShowAgain) {
        Promise.all([submit({ type: 'welcome' }), submit({ type: 'welcome_mobile' })]).then(() => {
          hide()
        }).catch((err) => {
          Modal.error({ content: err.msg })
        })
      } else {
        hide()
      }
    }

    return {
      ...toRefs(state),
      show,
      hide,
      onChange,
      closeModal
    }
  }

}
</script>

<style lang="less" scoped>
.onboarding {
  h3 {
    font-size: 2.2rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2.4rem;

    img {
      width: 2.5rem;
      transform: translateY(-0.2rem) translateX(0.5rem)
    }
  }
}

.onboarding-box {
  width: 100%;
  background: #eef4fb;
  padding: 0 4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .accordion {
    li {
      display: flex;
      align-items: baseline;
      margin-bottom: 3.5rem;
      cursor: pointer;

      h4 {
        font-size: 2rem;
        font-weight: 600;
        margin-top: 0;
        margin-bottom: 0.8rem
      }

      p {
        font-size: 1.5rem
      }

      i {
        width: 2.1rem;
        height: 2.1rem;
        background: #1890ff;
        display: block;
        border-radius: 50%;
        text-align: center;
        font-size: 1.3rem;
        line-height: 2.1rem;
        color: #fff;
        margin-right: 0.5rem;
        transform: translateY(-0.3rem)
      }
    }
  }
}

.onboarding-btm {
  padding: 3rem 4rem 2.5rem 4rem;
  position: relative;

  span {
    color: #555;
    font-size: 1.5rem;
    position: absolute;
    left: 4rem;
    bottom: 5rem;
  }

  button {
    background: #1890ff;
    color: #fff;
    font-size: 1.7rem;
    font-weight: 500;
    border-radius: 0.4rem;
    margin: 0 auto;
    width: 15rem;
    display: block;
    height: 5.5rem;
    line-height: 5.5rem;
  }
}

.slide-wrap {

}

</style>

<style lang="less" scoped>
.slide-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.slide-wrap .step-slider {
  width: 60rem;
  border-radius: 2rem;
  font-size: 5rem;
}

.slide-wrap .slide {
  border-radius: 2rem;
  overflow: hidden;
  height: 45rem;
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: column;
  justify-content: center;
}

.slide-wrap .txt1 {
  animation: txt 4s linear infinite;
}

.slide-wrap .txt2 {
  animation: txt 4s linear infinite;
  animation-delay: 2s;
  opacity: 0
}

.slide-wrap .slide1 h3 {
  font-size: 1.7rem;
  padding: 1.5rem;
  font-weight: 500;
  background: #182341;
  color: #fff
}

.slide-wrap .slide1 {
  padding: 4rem 3rem;
}

.slide-wrap .slide1 .box {
  width: 100%;
  transform: scale(0.85);
}

.slide-wrap .slide1 .cont {
  display: flex;
  justify-content: space-between;
  background: #fff
}

.slide-wrap .slide1 .left {
  padding: 2rem;
  width: 25%;
  border-right: 1px solid #ebebeb
}

.slide-wrap .slide1 .left li {
  background: #f3f3f3;
  border-radius: 0.5rem;
  height: 4.5rem;
  margin-bottom: 1rem
}

.slide-wrap .slide1 .left li.on {
  background: #e6f7ff;
  color: #1890ff
}

.slide-wrap .slide1 .right {
  width: 75%;
  text-align: center;
  padding: 8rem 2rem 5rem 2rem;
  position: relative
}

.slide-wrap .slide1 .right .file-img {
  position: absolute;
  bottom: 0;
  right: 0;
  animation: file-mov 4s linear infinite;
  opacity: 0;
}

.slide-wrap .slide1 .right h4 {
  font-size: 1.8rem;
  font-weight: 500;
  margin-bottom: 2rem
}

.slide-wrap .slide1 .right h4 .txt {
  position: absolute;
  left: 0;
  right: 0;
  top: 5rem
}

.slide-wrap .slide1 .right .input-txt {
  padding: 2.5rem;
  font-size: 1.6rem;
  text-align: left;
  margin-bottom: 2rem;
  background: #e6f7ff;
  border-radius: 0.5rem;
  color: #1890ff;
  animation: txt1 4s linear infinite;
  position: relative
}

.slide-wrap .slide1 .right .input-txt .txt {
  position: absolute;
  left: 2rem;
  top: 1.5rem;
  display: block
}

.slide-wrap .slide1 .right .click {
  background: #1890ff;
  color: #fff;
  font-size: 1.5rem;
  font-weight: 600;
  border-radius: 0.5rem;
  display: inline-block;
  padding: 1.2rem 4rem
}

.slide-wrap .slide2 .box {
  background: #fff;
  width: 30rem;
  margin: 1rem 0;
}

.slide-wrap .slide2 .top {
  font-size: 1.6rem;
  font-weight: 500;
  padding: 3rem 2rem 2rem 2rem;
}

.slide-wrap .slide2 .top i {
  background: #eafaf9;
  color: #27d0b0;
  width: 2.2rem;
  height: 2.2rem;
  line-height: 2.2rem;
  text-align: center;
  border-radius: 50%;
  font-size: 1.5rem;
  font-weight: 700;
  display: inline-block;
  margin-right: 0.5rem
}

.slide-wrap .slide2 .top i.yellow {
  background: #fdf7dc;
  color: #ffca10
}

.slide-wrap .slide2 .input-txt {
  border: 1px solid #ebebeb;
  padding: 1rem;
  font-size: 1.4rem;
  color: #aaa;
  font-weight: 400;
  margin: 0.5rem 2rem
}

.slide-wrap .slide2 .flex-sb {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem
}

.slide-wrap .slide2 span {
  display: inline-block;
  font-size: 1.4rem;
  width: 50%;
  text-align: center;
  padding: 1.5rem 1rem;
  color: #aaa;
  font-weight: 400;
  background: #f7f7f7;
  border-top: 1px solid #ebebeb
}

.slide-wrap .slide2 span:first-of-type {
  animation: choice1 4s linear infinite;
}

.slide-wrap .slide2 span:last-of-type {
  animation: choice2 4s linear infinite;
}

.slide-wrap .slide2 .box.add {
  font-size: 1.5rem;
  padding: 2rem;
  text-align: center
}

.slide-wrap .slide2 .choice {
  position: relative;
  height: 5rem;
}

.slide-wrap .slide2 .choice .txt {
  position: absolute;
  left: 0;
  right: 0;
  top: 0
}

.slide-wrap .slide2 .choice select {
  font-size: 1.4rem;
  background: #f3f3f3;
  color: #aaa;
  border-color: #ebebeb;
  font-weight: 400;
  border-radius: 0
}

.slide-wrap .slide2 .choice .txt2 {
  display: flex;
  justify-content: space-between;
  padding: 0 2rem
}

.slide-wrap .slide2 .choice .txt2 .input-txt {
  width: 100%;
  margin: 0
}

.slide-wrap .slide2 .txt2 {
  opacity: 0
}

.slide-wrap .slide3 .box {
  background: #fff;
  width: 36rem;
  margin: 3.5rem auto;
  position: relative;
  height: 100%
}

.slide-wrap .slide3 .gray {
  width: calc(100% - 4rem);
  height: 16rem;
  background: #f7f7f7;
  margin: 2rem
}

.slide-wrap .slide3 .top {
  text-align: center;
  font-size: 1.8rem;
  margin-top: 2rem;
  font-weight: 700
}

.slide-wrap .slide3 .sign-box {
  position: absolute;
  right: 2rem;
  bottom: 2rem;
  animation: view 4s linear infinite
}

.slide-wrap .slide3 .sign-box .label {
  background: #27d0b0;
  padding: 0.5rem;
  color: #fff;
  font-size: 1.3rem;
  width: 100%;
  text-align: center
}

.slide-wrap .slide3 .sign-box .square {
  width: 7rem;
  height: 7rem;
  background: #b2eee3
}

.slide-wrap .slide3 .input-txt {
  background: #b2eee3;
  font-size: 1.5rem;
  padding: 0.5rem;
  width: 15rem;
  position: absolute;
  left: 8.5rem;
  bottom: 4rem;
  animation: view 4s linear infinite
}

.slide-wrap .slide3 .tool div {
  padding: 1rem;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  border: 1px solid #ebebeb;
  width: 15rem;
  background: #fff
}

.slide-wrap .slide3 .tool .sign, .sign-mov {
  position: absolute;
  left: -6rem;
  bottom: 21rem;
  z-index: 999
}

.slide-wrap .slide3 .tool .sign-mov {
  animation: sign 4s linear infinite;
  z-index: 999;
}

.slide-wrap .slide3 .tool .textbox, .textbox-mov {
  position: absolute;
  left: -6rem;
  bottom: 16.5rem;
}

.slide-wrap .slide3 .tool .textbox-mov {
  animation: textbox 4s linear infinite;
  z-index: 999;
}

.slide-wrap .slide3 .tool .checkbox {
  position: absolute;
  left: -6rem;
  bottom: 12rem;
}

.slide-wrap .slide3 .label1 {
  font-size: 1.6rem;
  position: absolute;
  left: 2rem;
  bottom: 7.8rem;
}

.slide-wrap .slide3 .label2 {
  font-size: 1.6rem;
  position: absolute;
  left: 2rem;
  bottom: 4.5rem;
}

.slide-wrap .slide4 .box {
  background: #fff;
  width: 36rem;
  margin: 3.5rem auto;
  padding: 3.5rem
}

.slide-wrap .slide4 .box .top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.8rem;
}

.slide-wrap .slide4 .box .top img {
  width: 8rem
}

.slide-wrap .slide4 .box .switch {
  animation: off-gray 4s linear infinite;
  animation-delay: 2s;
  background: #1890ff;
  border-radius: 15rem;
  width: 7rem;
  height: 3.3rem;
  position: relative;
  transform: scale(0.7);
  margin-right: -1.5rem
}

.slide-wrap .slide4 .box .switch i {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  background: #fff;
  position: absolute;
  right: 0.5rem;
  bottom: 0.4rem;
  animation: switch 4s linear infinite;
  animation-delay: 2s;
}

.slide-wrap .slide4 .input-txt {
  border: 1px solid #ebebeb;
  padding: 1rem;
  font-size: 1.5rem;
  font-weight: 400;
}

.slide-wrap .slide4 h5 {
  margin: 2.5rem 0 0.5rem;
  font-size: 1.5rem;
  font-weight: 500
}

.slide-wrap .slide4 .txt-box {
  animation: onoff 4s linear infinite;
  animation-delay: 2s;
}

@keyframes txt {
  0% {
    opacity: 0;
  }
  2% {
    opacity: 1;
  }
  48% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes file-mov {
  0% {
    opacity: 0;
    bottom: 0;
    right: 0;
  }
  38% {
    opacity: 0;
    bottom: 0;
    right: 0;
  }
  42% {
    opacity: 0.9;
    bottom: 8rem;
    right: 5rem;
  }
  85% {
    opacity: 0.9;
    bottom: 8rem;
    right: 5rem;
  }
  95% {
    opacity: 0;
    bottom: 8rem;
    right: 5rem;
  }
  100% {
    opacity: 0;
    bottom: 8rem;
    right: 5rem;
  }
}

@keyframes choice1 {
  0% {
    color: #aaa;
    background: #f7f7f7
  }
  2% {
    color: #1890ff;
    background: #fff
  }
  48% {
    color: #1890ff;
    background: #fff
  }
  50% {
    color: #aaa;
    background: #f7f7f7
  }
  100% {
    color: #aaa;
    background: #f7f7f7
  }
}

@keyframes choice2 {
  0% {
    color: #1890ff;
    background: #fff
  }
  2% {
    color: #aaa;
    background: #f7f7f7
  }
  48% {
    color: #aaa;
    background: #f7f7f7
  }
  50% {
    color: #1890ff;
    background: #fff
  }
  100% {
    color: #1890ff;
    background: #fff
  }
}

@keyframes view {
  0% {
    opacity: 0;
  }
  29% {
    opacity: 0;
  }
  31% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes sign {
  0% {
    left: -6rem;
    bottom: 21rem;
  }
  22% {
    left: -6rem;
    bottom: 21rem;
  }
  26% {
    opacity: 1;
  }
  30% {
    left: 20rem;
    bottom: 2rem;
    opacity: 0;
  }

  100% {
    left: 20rem;
    bottom: 2rem;
    opacity: 0;
  }
}

@keyframes textbox {
  0% {
    left: -6rem;
    bottom: 16.5rem;
  }
  22% {
    left: -6rem;
    bottom: 16.5rem;
  }
  26% {
    opacity: 1;
  }
  30% {
    left: 2rem;
    bottom: 4rem;
    opacity: 0;
  }

  100% {
    left: 2rem;
    bottom: 4rem;
    opacity: 0;
  }
}

@keyframes switch {
  0% {
    right: 0.5rem;
  }
  5% {
    right: 4rem;
  }
  35% {
    right: 4rem;
  }
  40% {
    right: 0.5rem;
  }
  100% {
    right: 0.5rem;
  }
}

@keyframes onoff {
  0% {
    opacity: 1;
  }
  5% {
    opacity: 0;
  }
  35% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes off-gray {
  0% {
    background: #1890ff;
  }
  5% {
    background: #ccc;
  }
  35% {
    background: #ccc;
  }
  40% {
    background: #1890ff;
  }
  100% {
    background: #1890ff;
  }
}
</style>
