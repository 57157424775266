<template>
  <PolicyModal ref="policyModal" @confirm="showDefaultModal()"/>
  <OnboardingModalPC ref="onboardingModalPC" @confirm="showNoticeModal(0)"/>
  <OnboardingModalMOBILE ref="onboardingModalMOBILE" @confirm="showNoticeModal(0)"/>
  <!-- <PayappLinkModal ref="payappLinkModal" @confirm="showNoticeModal(0)"/> -->
  <Notice1Modal ref="notice1Modal" @confirm="showNoticeModal(1)"/>
  <Notice2Modal ref="notice2Modal" @confirm="showNoticeModal(2)"/>
  <Notice3Modal ref="notice3Modal"/>
</template>

<script>
import { ref } from 'vue'
import { useStore } from 'vuex'
import PolicyModal from 'components/policy/PolicyModal'
import OnboardingModalPC from './OnboardingModal_PC'
import OnboardingModalMOBILE from './OnboardingModal_MOBILE'
// import PayappLinkModal from './PayappLinkModal'
import Notice1Modal from './Notice1Modal'
import Notice2Modal from './Notice2Modal'
import Notice3Modal from './Notice3Modal'
import { NOTI1_VERSION, NOTI2_VERSION, NOTI3_VERSION } from '@/config/config'
import { useMediaQuery } from '@vueuse/core'

export default {
  components: {
    PolicyModal,
    OnboardingModalPC,
    OnboardingModalMOBILE,
    // PayappLinkModal,
    Notice1Modal,
    Notice2Modal,
    Notice3Modal
  },
  setup () {
    const policyModal = ref(null)
    const onboardingModalPC = ref(null)
    const onboardingModalMOBILE = ref(null)
    // const payappLinkModal = ref(null)
    const notice1Modal = ref(null)
    const notice2Modal = ref(null)
    const notice3Modal = ref(null)
    const store = useStore()

    const showDefaultModal = () => {
      const noti = store.state.user.userInfo.notifications
      if (!noti.policy) {
        policyModal.value.show()
      } else {
        if (!noti.welcome && !useMediaQuery('(max-width: 1199px)').value) {
          onboardingModalPC.value.show()
        } else if (!noti.welcomeMobile && useMediaQuery('(max-width: 1199px)').value) {
          onboardingModalMOBILE.value.show()
        } else {
          showNoticeModal(0)
        }
      }
    }

    const showNoticeModal = index => {
      const noti = store.state.user.userInfo.notifications
      if (index === 0) {
        if (NOTI1_VERSION !== 0 && (!noti.notice1 || NOTI1_VERSION > noti.notice1)) {
          notice1Modal.value.show()
        } else if (NOTI2_VERSION !== 0 && (!noti.notice2 || NOTI2_VERSION > noti.notice2)) {
          notice2Modal.value.show()
        } else if (NOTI3_VERSION !== 0 && (!noti.notice3 || NOTI3_VERSION > noti.notice3)) notice3Modal.value.show()
      } else if (index === 1) {
        if (NOTI2_VERSION !== 0 && (!noti.notice2 || NOTI2_VERSION > noti.notice2)) {
          notice2Modal.value.show()
        } else if (NOTI3_VERSION !== 0 && (!noti.notice3 || NOTI3_VERSION > noti.notice3)) notice3Modal.value.show()
      } else if (index === 2) {
        if (NOTI3_VERSION !== 0 && (!noti.notice3 || NOTI3_VERSION > noti.notice3)) notice3Modal.value.show()
      }
    }

    return {
      showDefaultModal,
      showNoticeModal,
      policyModal,
      onboardingModalPC,
      onboardingModalMOBILE,
      // payappLinkModal,
      notice1Modal,
      notice2Modal,
      notice3Modal
    }
  }
}
</script>
