<template>
  <div>
    <!--대량전송 툴팁-->
    <a-tooltip v-if="currentPageName === 'Template' || currentPageName === 'TeamTemplate'" :color="'#FFF'" :placement="placement" overlayClassName="signer-tooltip">
      <button class="btn-info" type="button">?</button>
      <template #title>
        <p class="title">템플릿</p>
        <p class="content">
          자주 사용하는 양식은 템플릿으로 만들어 간편하게 계약을 생성할 수 있습니다.<br><br>
          자세한 이용 방법은 이용 가이드를 참고해 주세요.
        </p>
        <a class="more" href="https://ucansign.oopy.io/5d733afe-73a8-4fe7-b02e-14acbf3ce0d0" target="_blank">이용가이드<i class="arrow arrow-sm arrow-blue right"></i></a>
      </template>
    </a-tooltip>
    <!--대량전송 툴팁-->
    <a-tooltip v-if="currentPageName === 'BulkTransmit'" :color="'#FFF'" :placement="placement" overlayClassName="signer-tooltip">
      <button class="btn-info" type="button">?</button>
      <template #title>
        <p class="title">대량전송</p>
        <p class="content">
          동일한 계약서 양식으로 다수의 사람에게 한번에 서명 요청할 수 있습니다.<br><br>
          자세한 이용 방법은 이용 가이드를 참고해 주세요.
        </p>
        <a class="more" href="https://ucansign.oopy.io/661d812f-e6c1-4a05-873c-1180f4265c7e" target="_blank">이용가이드<i class="arrow arrow-sm arrow-blue right"></i></a>
      </template>
    </a-tooltip>
    <!--
    문서 편집기 툴팁
    <a-tooltip v-if="currentPageName === 'EditingDocuments'" :placement="placement" :color="'#FFF'" overlayClassName="signer-tooltip">
      <button type="button" class="btn-info">?</button>
      <template #title>
        <p class="title">문서편집기</p>
        <p class="content">
          기존에 사용하던 계약서 원본 문서 위에 사인, 도장, 텍스트, 체크박스를 추가하여 간편하게 문서를 편집할 수 있습니다.<br><br>
          자세한 이용 방법은 이용 가이드를 참고해 주세요.
        </p>
        <a href="https://ucansign.oopy.io/editingdocuments" target="_blank" class="more">이용가이드<i class="arrow arrow-sm arrow-blue right"></i></a>
      </template>
    </a-tooltip>
    부계정관리 툴팁
    <a-tooltip v-if="currentPageName === 'Subaccount'" :placement="placement" :color="'#FFF'" overlayClassName="signer-tooltip">
      <button type="button" class="btn-info">?</button>
      <template #title>
        <p class="title">부계정 관리</p>
        <p class="content">
          조직 내 팀과 부계정 정보를 관리할 수 있습니다.
          팀은 3단 트리 구조 형태로 구성할 수 있습니다.<br><br>
          부계정은 공동관리자, 중간관리자, 일반 역할로 구분하여 추가할 수 있으며, 각 역할마다 기능별 접근 권한이 달라집니다.<br><br>
          자세한 내용은 이용 가이드를 참고해 주세요.
        </p>
        <a href="https://ucansign.oopy.io/ef555cb7-229b-467f-b787-c25c94dbb261" target="_blank" class="more">이용가이드<i class="arrow arrow-sm arrow-blue right"></i></a>
      </template>
    </a-tooltip>
      -->
  </div>
</template>

<script>
import { useRoute } from 'vue-router'
import { computed } from 'vue'
import { useMediaQuery } from '@vueuse/core'

export default {
  setup () {
    const route = useRoute()
    const placement = computed(() => {
      const isMobile = useMediaQuery('(max-width: 768px)').value
      return isMobile ? 'bottom' : 'right'
    })
    const currentPageName = computed(() => {
      return route.name
    })
    return {
      placement,
      currentPageName
    }
  }
}
</script>
