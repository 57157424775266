<template>
  <a-modal v-model:visible="visible"
           :bodyStyle="{ padding: 0 }"
           :closable="false"
           :footer="null"
           :keyboard="false"
           :maskClosable="false"
           :width="650"
           class="ucs-modal">
    <div class="modal-header bg-gray">
      <h2>첨부파일</h2>
      <span class="modal-close" role="button" @click="visible = false">닫기</span>
    </div>
    <div class="modal-content">
      <div class="modal-body">
        <a-table :columns="state.columns" :data-source="state.data" :loading="state.isLoading" :pagination="false" rowKey="attachmentId" size="small">
          <template #action="{ record }">
            <div class="download">
              <!--<a-button type="link" @click="showFile(record)">파일보기</a-button>-->
              <a-button type="link" @click="download(record)">다운로드</a-button>
            </div>
          </template>
        </a-table>
        <div class="btn-row mt15">
          <div class="btn-horizon">
            <a-button class="btn" type="primary" @click="visible = false">확인</a-button>
          </div>
        </div>
      </div>
    </div>
  </a-modal>
</template>

<script setup>
import { defineExpose, reactive, ref } from 'vue'
import { getAttachmentFile, getAttachments } from 'api/sign'
import { downloadFile } from 'utils/helper'

const visible = ref(false)
const state = reactive({
  documentId: null,
  attachments: [],
  data: [],
  columns: [
    {
      title: '첨부파일 종류',
      dataIndex: 'attachmentType'
    },
    {
      title: '첨부한 사람',
      dataIndex: 'participantName'
    },
    {
      title: '파일이름',
      dataIndex: 'fileName',
      ellipsis: true
    },
    {
      title: '',
      dataIndex: 'file',
      slots: { customRender: 'action' }
    }
  ],
  isLoading: false
})

const show = documentId => {
  state.documentId = documentId
  visible.value = true
  fetchData(documentId)
}

const fetchData = async (documentId) => {
  try {
    state.isLoading = true
    state.data = []
    const res = await getAttachments(documentId, true)
    state.attachments = res.result.filter(item => item.file)
    state.attachments.forEach((item) => {
      const data = {
        attachmentId: item.attachmentId,
        attachmentType: item.request.attachmentType,
        participantName: item.participant.name,
        fileName: item.file.name,
        file: item.file
      }
      state.data.push(data)
    })
    state.isLoading = false
  } catch (error) {
    console.log(error)
    state.isLoading = false
  }
}

const download = (record) => {
  getAttachmentFile(state.documentId, record.attachmentId).then(res => {
    downloadFile(res.result.url, record.file.name)
  })
  // downloadFile(record.file.url, record.file.name)
}

/*
const showFile = (record) => {
  window.open(record.file.url)
}
 */
defineExpose({ show })

</script>

<style lang="less" scoped>
.modal-body {
  /deep/ .ant-table-thead > tr > th {
    background: none;
  }
}

.download {
  display: flex;

  button {
    padding: 5px;
  }
}

@media screen and (max-width: 768px ) {
  .download {
    display: block;
  }
}
</style>
